import React from 'react'
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import background from '../../outside/background.webp';

export default function SectionOne() {

    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });

    const sectionStyle = isMobile
        ? {}
        : {
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
        };

    return (
        <>
            <section className="px-0 py-8 lg:py-48 md:px-0" style={sectionStyle}>
                <div className="container items-center w-full px-0 mx-auto xl:px-5">
                    <div className="flex flex-wrap items-center">
                        <div className="w-full lg:w-4/6 md:px-3">
                            <div className="mx-auto md:bg-[#fff0] max-w-7xl px-5 sm:px-6 lg:pl-32 lg:pr-24  pb-5 lg:text-left text-center">
                                <h1 className="max-w-4xl font-roboto font-bold text-24 lg:text-50 leading-36 lg:leading-60 mt-4   text-slate-900 lg:text-white">
                                    <span className="inline-block">We Build Apps  <br className='lg:block hidden' /> for Businesses. <span className='lg:text-white text-blue-600'>We also Teach App Development Courses.</span>
                                    </span>
                                </h1>
                                <p className="mt-5 max-w-2xl  font-roboto font-medium text-17 lg:text-24 tracking-tight text-slate-700 lg:text-white sm:mt-6">
                                    We create intuitive, tailored app solutions that empower businesses to thrive. For people that want to break into tech as an App developer we offer the most comprehensive course on the market.
                                </p>
                                <Link className=""
                                    to="/contact">
                                    <button className="relative w-full md:w-80 block md:inline-flex mt-6 items-center justify-center p-0.5 lg:mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
                                        <span className="relative w-full block md:inline  px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                            Contact Us
                                        </span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div >
                </div >
            </section >
        </>
    )
}
